<template>
  <div>
    <v-data-table
      :items="work_orders"
      :headers="headers"
      :loading="loading"
      :search="search"
      :items-per-page="200"
      dense
      sort-by="work_order_number"
      :sort-desc="true"
    >
      <!--Click to view Work Order-->
      <template v-slot:item.work_order_number="{ item }">
        <a @click="viewWorkOrder(item)">{{ item.work_order_number }}</a>
      </template>
      <!--Click to view Sales Order-->
      <template v-slot:item.sales_order_number="{ item }">
        <a @click="viewSalesOrder(item)">{{ item.sales_order_number }}</a>
      </template>
      <!--Empty-->
      <template v-slot:no-data>
        <EmptyTableSlot :type="'Work Orders'" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import db from "../../../../components/firebaseInit";
export default {
  name: "CustomerWorkOrdersTable",
  props: ["customer_name"],
  components: {
    EmptyTableSlot: () => import("../Tables/EmptyTableSlot.vue"),
  },
  data() {
    return {
      search: "",
      loading: false,
      work_orders: [],
      headers: [
        { text: "WO#", value: "work_order_number", align: "start" },
        { text: "Related SO#", value: "sales_order_number" },
        { text: "Date", value: "work_order_date_created" },
        { text: "Production Status", value: "work_order_production_status" },
      ],
    };
  },
  created() {
    this.getWorkOrders();
  },
  methods: {
    getWorkOrders() {
      const docRef = db
        .collection("work_orders")
        .where("customer_name", "==", this.customer_name);
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.work_orders.push(data);
          });
        })
        .catch((error) => error);
    },
    //
    viewWorkOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-work-order/${item.work_order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
    //
    viewSalesOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.sales_order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>